import {call, put, takeEvery} from 'redux-saga/effects' 
import {getGeneralData} from '../services/services'
import {
    FETCH_RESOURCE_LOADED,
    FETCH_RESOURCE_ERROR,
} from '../actions/auxiliar_action'

import {
    FETCH_GENERAL_DATA,
    FETCH_GENERAL_DATA_SUCCESS
} from '../actions/auxiliar_action'

function* fetchGetDataSaga({payload}){
    // console.log(payload)
    try {
        let tokenStr = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwOWI2NTJhOS03ODdjLTQ0NzYtODIwMy1lOTA2ZDY2ZGExZWYiLCJpZGVudGl0eSI6ImFkbWluQGFkbWluLmNvbSIsInR5cGUiOiJhY2Nlc3MiLCJmcmVzaCI6ZmFsc2UsImV4cCI6MTYwMjAwODUxMSwiaWF0IjoxNTk5NDE2NTExLCJuYmYiOjE1OTk0MTY1MTF9.vnnLVB_0idJJItq_7J9_oKU7fZjcF8AwTkfnZZlir2c';

        const resGeneralDataFeed = yield call(getGeneralData,tokenStr, payload.params.competition, payload.params.season)
        let data = {}
        if(resGeneralDataFeed.status === 200){
            yield put({type: FETCH_GENERAL_DATA_SUCCESS, payload: resGeneralDataFeed.data})
            yield put({type: FETCH_RESOURCE_LOADED})
        } else {
            yield put({type: FETCH_RESOURCE_ERROR, payload: data})
        }
    } catch(error){
        yield put({type: FETCH_RESOURCE_ERROR, payload: error})
    }
}

function* watchGetDataSagas(){
    yield takeEvery(FETCH_GENERAL_DATA, fetchGetDataSaga)
}

export default [watchGetDataSagas]

// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE1ODAxMDEwNTYsImZyZXNoIjpmYWxzZSwiaWRlbnRpdHkiOiJhZG1pbkBhZG1pbi5jb20iLCJpYXQiOjE1ODAxMDEwNTYsInR5cGUiOiJhY2Nlc3MiLCJqdGkiOiIwMzI4MzdkZC1kYTdiLTRjZGMtODdjOS00ZWQ1OTM1NjkzYjIiLCJleHAiOjE1ODI2OTMwNTZ9.veBoRI1VQs4gAGT73Txbl0HKMsRwMA9eSK5q9m_Sak8