import {all, call, put, takeEvery} from 'redux-saga/effects' 
import {getTable} from '../services/services'
import {
    FETCH_RESOURCE_LOADED,
    FETCH_RESOURCE_ERROR,
} from '../actions/auxiliar_action'

import {
    FETCH_TABLES,
    FETCH_TABLES_SUCCESS
} from '../actions/tables_action'

function* fetchTableSaga({payload}){
    try {
        let tokenStr = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwOWI2NTJhOS03ODdjLTQ0NzYtODIwMy1lOTA2ZDY2ZGExZWYiLCJpZGVudGl0eSI6ImFkbWluQGFkbWluLmNvbSIsInR5cGUiOiJhY2Nlc3MiLCJmcmVzaCI6ZmFsc2UsImV4cCI6MTYwMjAwODUxMSwiaWF0IjoxNTk5NDE2NTExLCJuYmYiOjE1OTk0MTY1MTF9.vnnLVB_0idJJItq_7J9_oKU7fZjcF8AwTkfnZZlir2c';

        const [resGoalsFeed] = yield all (
            [
                call(getTable,tokenStr, payload.params.competition, payload.params.season, payload.params.table, payload.params.play_offs),
            ]
        )
        
        let data = {}
        if(resGoalsFeed.status === 200){
            console.log(resGoalsFeed)
            yield put({type: FETCH_TABLES_SUCCESS, payload: resGoalsFeed.data})
            yield put({type: FETCH_RESOURCE_LOADED})
        } else {
            yield put({type: FETCH_RESOURCE_ERROR, payload: data})
        }
    } catch(error){
        yield put({type: FETCH_RESOURCE_ERROR, payload: error})
    }
}

function* watchTableSagas(){
    yield takeEvery(FETCH_TABLES, fetchTableSaga)
}

export default [watchTableSagas]