import {call, put, takeEvery} from 'redux-saga/effects' 
import axios from 'axios'
import querystring from 'querystring';
import {
    FETCH_RESOURCE_LOADED,
    FETCH_RESOURCE_ERROR,
} from '../actions/auxiliar_action'

import {
    FETCH_LOGIN,
    FETCH_LOGIN_SUCCESS
} from '../actions/login_action'

function getToken() {
    const url =`https://api2.futbolmetrics.com/api/login`
    return axios.post(
      url, 
      querystring.stringify(
      {
        email: 'admin@admin.com',
        password: 'Ki$$m3'
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      ).then(res => res)
}

function* fetchLoginSaga(){
    try {
        const resTokenLogin = yield call(getToken)
        // console.log(resTokenLogin.data.token)
        if(resTokenLogin.status === 200){
            let data ={}
            data.token = resTokenLogin.data;
            // console.log(data.token.jwt)
            yield put({type: FETCH_LOGIN_SUCCESS, payload: resTokenLogin.data})
            yield put({type: FETCH_RESOURCE_LOADED})
        } else {
            yield put({type: FETCH_RESOURCE_ERROR, payload: resTokenLogin.data})
        }
    } catch(error){
        yield put({type: FETCH_RESOURCE_ERROR, payload: error})
    }
}

function* watchLoginSagas(){
    yield takeEvery(FETCH_LOGIN, fetchLoginSaga)
}

export default [watchLoginSagas]