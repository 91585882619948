import {
    FETCH_RESOURCE_LOADING,
    FETCH_RESOURCE_LOADED,
    FETCH_RESOURCE_ERROR,
} from '../actions/auxiliar_action'

import {
    FETCH_TABLES_SUCCESS
} from '../actions/tables_action'

export default (state = {}, action) => {
    switch(action.type){
        case FETCH_RESOURCE_LOADING: {
            return {...state, loading: state.loading + 1}
        }
        case FETCH_RESOURCE_LOADED: {
            return {...state, loading: state.loading -1}
        }
        case FETCH_RESOURCE_ERROR: {
            return {...state, fetchError: action.payload, loading: false}
        }
        case FETCH_TABLES_SUCCESS: {
            return {...state, ...action.payload, fetchError: null}
        }
        default: {
            return state
        }
    }
}