export const table_columns = {
    team_goals_agg: {
        goals_time_period: {
            title: 'Tabla de Goles por Equipo por Período de Tiempo',
            fields: [
                {
                    header: 'Total de Goles Marcados',
                    accessor: 'goals_total',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'Primer Tiempo',
                    accessor: 'first_half_of_regular_time',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Segundo Tiempo',
                    accessor: 'second_half_of_regular_time',
                    progress: false,
                    color: 'orange'
                }
            ]
        },
        goals_fraction_time: {
            title: 'Tabla de Goles por Fracción de Tiempo',
            fields: [
                {
                    header: '0-15',
                    accessor: 'time_quarter_1',
                    progress: false,
                    color: 'teal'
                }, {
                    header: '15-30',
                    accessor: 'time_quarter_2',
                    progress: false,
                    color: 'grey'
                }, {
                    header: '30-45',
                    accessor: 'time_quarter_3',
                    progress: false,
                    color: 'orange'
                }, {
                    header: '45-60',
                    accessor: 'time_quarter_4',
                    progress: false,
                    color: 'violet'
                }, {
                    header: '60-75',
                    accessor: 'time_quarter_5',
                    progress: false,
                    color: 'black'
                }, {
                    header: '75-90',
                    accessor: 'time_quarter_6',
                    progress: false,
                    color: 'blue'
                }
            ]
        }, 
        goals_type: {
            title: 'Tipos de Goles',
            fields: [
                {
                    header: 'Total de Goles Marcados',
                    accessor: 'goals_total',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'De Jugada',
                    accessor: 'open_play',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'De Cabeza',
                    accessor: 'head',
                    progress: false,
                    color: 'orange'
                }, {
                    header: 'Tiro Libre',
                    accessor: 'direct',
                    progress: false,
                    color: 'violet'
                }, {
                    header: 'Penal',
                    accessor: 'penalty',
                    progress: false,
                    color: 'black'
                }, {
                    header: 'Autogoles a favor',
                    accessor: 'own_goal_forced',
                    progress: false,
                    color: 'blue'
                }
            ]
        }
    },
    team_shots_agg: {
        shots_time_period: {
            title: 'Disparos por Tiempo',
            fields: [
                {
                    header: 'Total de Disparos',
                    accessor: 'shots',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'Primer Tiempo',
                    accessor: 'first_half_of_regular_time',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Segundo Tiempo',
                    accessor: 'second_half_of_regular_time',
                    progress: false,
                    color: 'orange'
                }
            ]
        },
        shots_total: {
            title: 'Tipos de Disparos',
            fields: [
                {
                    header: 'Total de Disparos',
                    accessor: 'shots',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'Goles Marcados',
                    accessor: 'goals',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Al Arco',
                    accessor: 'on_target',
                    progress: false,
                    color: 'orange'
                }, {
                    header: 'Al Poste',
                    accessor: 'on_the_post',
                    progress: false,
                    color: 'violet'
                }, {
                    header: 'Afuera',
                    accessor: 'off_target',
                    progress: false,
                    color: 'black'
                }
            ]
        }, 
        shots_efect: {
            title: 'Efectividad de Disparos en Goles',
            fields: [
                {
                    header: 'Efectividad',
                    progress: true,
                    accessor: 'goals_by_shot',
                    color: 'teal'
                }, {
                    header: 'Goles Marcados',
                    accessor: 'goals',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Disparos',
                    accessor: 'shots',
                    progress: false,
                    color: 'orange'
                }
            ]
        }
    },
    team_fouls_agg: {
        foul_committed: {
            title: 'Faltas Cometidas de Equipo por Tiempo',
            fields: [
                {
                    header: 'Total de Faltas',
                    accessor: 'fouls_committed',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'Faltas en el Primer Tiempo',
                    accessor: 'fouls_committed_first_half_of_regular_time',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Faltas en el Segundo Tiempo',
                    accessor: 'fouls_committed_second_half_of_regular_time',
                    progress: false,
                    color: 'orange'
                }
            ]
        },
        foul_average: {
            title: 'Total de Faltas Cometidas en Promedio por Partido',
            fields: [
                {
                    header: 'Promedio',
                    accessor: 'fouls_committed_by_game',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'Faltas',
                    accessor: 'fouls_committed',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Partidos Jugados',
                    accessor: 'n_games',
                    progress: false,
                    color: 'orange'
                }
            ]
        }, 
        foul_received: {
            title: 'Total de Faltas Recibidas',
            fields: [
                {
                    header: 'Total',
                    accessor: 'fouls_received',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'Primer Tiempo',
                    accessor: 'fouls_received_first_half_of_regular_time',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Segundo Tiempo',
                    accessor: 'fouls_received_second_half_of_regular_time',
                    progress: false,
                    color: 'orange'
                }
            ]
        }
    },
    team_cards_agg: {
        card_total: {
            title: 'Tarjetas Recibidas',
            fields: [
                {
                    header: 'Total',
                    accessor: 'total_cards',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'Tarjetas Amarillas',
                    accessor: 'yellow_card',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Tarjetas Rojas',
                    accessor: 'red_card',
                    progress: false,
                    color: 'orange'
                }
            ]
        }
    },
    team_pass_stats_agg_p1: {
        pass_total: {
            title: 'Tabla de Pases',
            fields: [
                {
                    header: 'Total',
                    accessor: 'pass',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'Pases Correctos',
                    accessor: 'pass_good',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Pases Incorrectos',
                    accessor: 'pass_missed',
                    progress: false,
                    color: 'orange'
                }
            ]
        },
        pass_efect: {
            title: 'Efectividad en Pases',
            fields: [
                {
                    header: 'Total',
                    accessor: 'pass_acc',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'Pases Correctos',
                    accessor: 'pass_good',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Pases Totales',
                    accessor: 'pass',
                    progress: false,
                    color: 'orange'
                }
            ]
        }
    }, 
    team_fouls_penalty_agg: {
        penalty: {
            title: 'Penales',
            fields: [
                {
                    header: 'Total',
                    accessor: 'penalty_total',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'Por Falta',
                    accessor: 'penalty_foul',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Por Mano',
                    accessor: 'penalty_handball',
                    progress: false,
                    color: 'orange'
                }
            ]
        }
    },
    team_action_count_agg: {
        offsides: {
            title: 'Fuera de Juego',
            fields: [
                {
                    header: 'Total',
                    accessor: 'total',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'Primer Tiempo',
                    accessor: 'first_half_of_regular_time',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Segundo Tiempo',
                    accessor: 'second_half_of_regular_time',
                    progress: false,
                    color: 'orange'
                }
            ]
        },
        corners: {
            title: 'Tiros de Esquina',
            fields: [
                {
                    header: 'Total',
                    accessor: 'total',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'Primer Tiempo',
                    accessor: 'first_half_of_regular_time',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Segundo Tiempo',
                    accessor: 'second_half_of_regular_time',
                    progress: false,
                    color: 'orange'
                }
            ]
        },
        steals: {
            title: 'Robos',
            fields: [
                {
                    header: 'Total',
                    accessor: 'total',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'Primer Tiempo',
                    accessor: 'first_half_of_regular_time',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Segundo Tiempo',
                    accessor: 'second_half_of_regular_time',
                    progress: false,
                    color: 'orange'
                }
            ]
        }
    },
    team_assist_agg: {
        assist: {
            title: 'Asistencias',
            fields: [
                {
                    header: 'Total',
                    accessor: 'total',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'Primer Tiempo',
                    accessor: 'first_half_of_regular_time',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Segundo Tiempo',
                    accessor: 'second_half_of_regular_time',
                    progress: false,
                    color: 'orange'
                }
            ]
        }
    },
    player_goals_agg: {
        goal_player: {
            title: 'Jugadores por Goles',
            fields: [
                {
                    header: 'Total de Goles Marcados',
                    accessor: 'goals_total',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'Primer Tiempo',
                    accessor: 'first_half_of_regular_time',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'Segundo Tiempo',
                    accessor: 'second_half_of_regular_time',
                    progress: false,
                    color: 'orange'
                }
            ]
        },
        player_shot_of_goal: {
            title: 'Tipo de Gol por Jugador',
            fields: [
                {
                    header: 'Total de Goles Marcados',
                    accessor: 'goals_total',
                    progress: true,
                    color: 'teal'
                }, {
                    header: 'De Jugada',
                    accessor: 'open_play',
                    progress: false,
                    color: 'grey'
                }, {
                    header: 'De Cabeza',
                    accessor: 'head',
                    progress: false,
                    color: 'orange'
                }, {
                    header: 'Tiro Libre',
                    accessor: 'direct',
                    progress: false,
                    color: 'violet'
                }, {
                    header: 'Penal',
                    accessor: 'penalty',
                    progress: false,
                    color: 'black'
                }
            ]
        }
    }
}