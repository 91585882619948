import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './App.css';
import TableDataAggregate from './components/dataTable'
import PageNotFound from './components/NotFoundPage'

function App() {
  const texto = window.location.pathname.split("/")
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          {texto.length === 6 ?
            <Route path = '/:Competition/:Season/:Table/:table_option/:pagination' component = {TableDataAggregate}/> :
            <Route path = '/:Competition/:Season/:Table/:table_option/:pagination/:play_offs' component = {TableDataAggregate}/>
          }
          <Route path = '/404' component = {PageNotFound}/>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
