import axios from 'axios'
import {config} from '../config'

axios.defaults.baseURL = config.fmsApiUrl
axios.defaults.headers.post['Content-Type'] = 'application/json'

/**
 * Set Authorization header for requests
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export const setAuthToken = (token) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  }
  
  /**
   * Requests a URL, returning a promise.
   *
   * @param  {string} url       The URL we want to request
   * @param  {object} [options] The options we want to pass to "fetch"
   * @return {object}           An object containing either "data" or "err"
   */
  export const request = (options) => {
      console.log(options.url)
    const url = `${options.url}`
    return axios.get(
        url, 
        {
            headers: { 
                Authorization : 'Bearer ' + options.token,
            } 
        }
    ).then(res => res)
    .catch(err => err.response)
}
  