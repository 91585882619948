import { combineReducers } from 'redux';
import table_reducer from './table_reducer'
import general_data_reducer from './general_data_reducer'
import login_reducer from './login_reducer'

const rootReducer = combineReducers({
    table_reducer,
    general_data_reducer,
    login_reducer
})

export default rootReducer;