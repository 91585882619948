import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import rootReducer from './reducers';
import createSagaMiddleware from "redux-saga";
import rootSaga from './sagas/index';
var createHistory = require('history').createBrowserHistory;


export const history = createHistory()
export const sagaMiddleware = createSagaMiddleware();

const initialState = {
}

const enhancers = []
const middleware = [routerMiddleware(history)]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware, sagaMiddleware),
  ...enhancers
)

export default createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers,
)

sagaMiddleware.run(rootSaga);