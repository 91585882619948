import { FETCH_LOGIN_SUCCESS } from '../actions/login_action'
import { FETCH_RESOURCE_ERROR } from '../actions/auxiliar_action'

export default function(state = {token: null}, { type, payload }){
//   console.log(payload) { type, payload }
    switch(type){
    case FETCH_LOGIN_SUCCESS:
      return {...state, token: payload}
    case FETCH_RESOURCE_ERROR: {
        return {...state, fetchError: payload, loading: false}}
    default: 
      return state;
    }
}