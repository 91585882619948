export const FETCH_GENERAL_DATA = 'FETCH_GENERAL_DATA'
export const FETCH_GENERAL_DATA_SUCCESS = 'FETCH_GENERALDATA_SUCCESS'

export const FETCH_RESOURCE_LOADING = 'FETCH_RESOURCE_LOADING'
export const FETCH_RESOURCE_LOADED = 'FETCH_RESOURCE_LOADED'
export const FETCH_RESOURCE_ERROR = 'FETCH_RESOURCE_ERROR '

export const fetchGetGeneralData = (payload) => {
    return {
        type: FETCH_GENERAL_DATA,
        payload: {
            params:{
                competition: payload.competition,
                season: payload.season
            }
        }
    }
}