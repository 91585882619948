import React from 'react';
import PageNotFound from './assets/images/PageNotFound2.png';

class NotFoundPage extends React.Component{
    render(){
        return( 
          <div>
            <img src={PageNotFound} alt='404 error'/>
            <p style={{textAlign:"center"}}>
            </p>
          </div>
        )
    }

}
export default NotFoundPage;