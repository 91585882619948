export const FETCH_TABLES = 'FETCH_TABLES'
export const FETCH_TABLES_SUCCESS = 'FETCH_TABLES_SUCCESS'

export const fetchTeamsGoals = (payload) => {
    return {
        type: FETCH_TABLES,
        payload: {
            params: {
                competition: payload.competition,
                season: payload.season,
                table: payload.table,
                play_offs: payload.play_offs
            }
        }
    }
}