import {all} from 'redux-saga/effects'

import tableSaga from './tables_sagas'
import general_data_saga from './general_data_saga'
import login from './login_saga'

export default function* root() {
    yield all([
        ...general_data_saga.map(saga => saga()),
        ...tableSaga.map(saga => saga()),
        ...login.map(saga => saga())
    ])
}