import {request} from '../utils/request'

const baseUrl = 'https://api2.futbolmetrics.com/api/media/';

export function getTable(token, competition, season, table, play_offs) {
    console.log(play_offs)
    return request({
        url: baseUrl + `${competition}/2020/${table}?play_offs=${play_offs}`,
        token: token
    })
}

export function getGeneralData(token, competition, season) {
    return request({
        url: baseUrl + `${competition}/2020?format=JSON`,
        token: token
    })
}