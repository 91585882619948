import React, { Component } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux'
import {fetchTeamsGoals} from '../actions/tables_action'
import {fetchGetGeneralData} from '../actions/auxiliar_action'
import {fetchLogin} from '../actions/login_action'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { Progress, Label, Statistic, Loader } from 'semantic-ui-react'
import { table_columns } from '../utils/teams_names'
import {Redirect} from 'react-router-dom'
import PageNotFound from './NotFoundPage'
import ReactGA from 'react-ga'

class TableDataAggregate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            higher: 0
        }
    }

    componentDidMount(){
        ReactGA.initialize('UA-149164085-1');
        ReactGA.pageview(window.location.pathname);

        const {Competition, Season, Table, play_offs} = this.props.match.params
        this.props.fetchGetGeneralData({
            competition: Competition,
            season: Season
        })

        this.props.fetchTeamsGoals({
            competition: Competition, 
            season: Season, 
            table: Table,
            play_offs: play_offs
        })
    }

    componentWillReceiveProps(nextProps){
        const {Table, table_option} = this.props.match.params
        var mayor = 0, arrNew = [];
        const keyProps = (nextProps.table_reducer && nextProps.table_reducer.data && Table !== 'team_action_count_agg') ? Object.keys(nextProps.table_reducer.data) : 
                         (nextProps.table_reducer && nextProps.table_reducer.data && Table === 'team_action_count_agg') ? Object.keys(nextProps.table_reducer.data[table_option]) : 
                         ['null']

        this.saveDataState(keyProps, Table, nextProps, arrNew, table_option)
        if(table_columns[Table][table_option]) mayor = this.getHigherValue(Table, table_option, arrNew, mayor);
        this.setState({data: arrNew, higher: mayor})
    }

    saveDataState(keyProps, Table, nextProps, arrNew, table_option){
        for(let i = 0; i < keyProps.length; i++) {
            if(Table !== 'team_action_count_agg') {
                arrNew[i] = nextProps.table_reducer.data ? nextProps.table_reducer.data[keyProps[i]] : []
            } else {
                arrNew[i] = nextProps.table_reducer.data ? nextProps.table_reducer.data[table_option][keyProps[i]] : []
            }
        }
    }

    getHigherValue(Table, table_option, arrNew, mayor){
        for(let i = 0; i < arrNew.length; i++){
            if(arrNew[i][table_columns[Table][table_option]['fields'][0].accessor] > mayor) {
                mayor = arrNew[i][table_columns[Table][table_option]['fields'][0].accessor]
            }
        }
        return mayor
    }

    sortFunction(data) {
        data.sort(function(a,b){
            return b.goals_total - a.goals_total
        })
    }

    getDynamicColumnsData(Table, table_option){
        var dinamicColumnsTable = []
        for(let i = 0; i < table_columns[Table][table_option]['fields'].length; i++){
            dinamicColumnsTable[i] = {
                Header: table_columns[Table][table_option]['fields'][i].header.toUpperCase() ? table_columns[Table][table_option]['fields'][i].header.toUpperCase() : '',
                accessor: table_columns[Table][table_option]['fields'][i].accessor ? table_columns[Table][table_option]['fields'][i].accessor : null,
                Cell: row => {
                    // console.log(row.row.player_id)
                    if(table_columns[Table][table_option]['fields'][i].progress){
                        if(row.value % 1 === 0 && table_option !== 'foul_average') {
                            return <div><Progress color = 'teal' progress = 'value' value = {row.value} total = {this.state.higher}/></div>
                        } else if(table_option === 'foul_average'){
                            return <div><Progress color = 'teal' progress = 'value' value = {row.value.toFixed(1)} total = {this.state.higher}/></div>
                        } else if(table_option === 'shots_efect'){
                            const percentValue = (row.value * 100).toFixed(1)
                            return <div><div style = {{width: '80%', display:'inline-block', float: 'left'}}><Progress color = 'teal' value = {percentValue} total = {this.state.higher*100}/></div>  <div style = {{float: 'right'}}>{percentValue+'%'}</div></div>
                        } else {
                            const percentValue = (row.value * 100).toFixed(1)
                            return <div><Progress color = 'teal' percent={percentValue} progress/></div>
                        }
                        
                    } else {
                        return <Label color = {table_columns[Table][table_option]['fields'][i].color}>{row.value}</Label>
                    }
                }
            }
        }
        return dinamicColumnsTable
    }

    render() {
        const data = this.state.data.filter(val => val.player_id !== '1010136')
        const {Table, table_option, pagination, play_offs} = this.props.match.params
        var dinamicColumnsTable = []
        
        if(table_columns[Table][table_option]) {
            dinamicColumnsTable = this.getDynamicColumnsData(Table, table_option)
        } else {
            return (
                    <Redirect to = '/404' component = {PageNotFound}/>
            )
        }
        
        const columns = [{
            Header: table_columns[Table][table_option]['title'].toUpperCase(),
            
            columns: [
                {
                Header: 'N°',
                accessor: 'num',
                minWidth: 50,
                maxWidth: 50,
                Cell: row => {
                        return (
                            <Statistic color = 'black' size = 'mini'><Statistic.Value>{row.viewIndex + 1}</Statistic.Value></Statistic>
                        )
                }
            }, {
                Header: Table === 'player_goals_agg' ? 'Jugador'.toUpperCase() : 'Equipo'.toUpperCase(),
                accessor: Table === 'player_goals_agg' ? 'player_id' : 'team_id',
                minWidth: 220,
                maxWidth: 220,
                Cell: row => {
                    const {general_data_reducer} = this.props
                    const fil = this.state.data.filter(val => val.player_id === row.value)
                    const logo = fil[0] ? fil[0].team_id : row.value
                    if(!general_data_reducer.data){
                        return <Loader size = 'tiny' active inline='centered'/>
                    } else {
                        return <div style={{float: 'left'}}><img height={20} src={require(`../teamsLogos/${logo}.png`)} alt = 'team logo'/> {(
                            general_data_reducer.data ? 
                                ((fil && fil[0] && fil[0].player_id) ? 
                                general_data_reducer.data.player_data[`${row.value},${fil[0].team_id}`].name.toUpperCase() : 
                                general_data_reducer.data.team_data[row.value].team_name.toUpperCase())
                             : ''
                             )}</div> 
                    }
                },
                style: {
                    fontSize: 12
                }
            }, 
        ].concat(dinamicColumnsTable)
        }]

        if(Table === 'player_goals_agg') {
          this.sortFunction(data)
        } 
        
        var valuePagination = parseInt(pagination)
        return(
            <div>        
                <ReactTable
                    data={Table === 'player_goals_agg' ? data.slice(0, pagination) : data}
                    columns = {columns}
                    defaultPageSize= {(play_offs === 'true' && valuePagination > 8) ? 8 : valuePagination}
                    defaultSorted = {[{
                        id: table_columns[Table][table_option]['fields'][0].accessor,
                        desc: true
                    }]}
                    // showPagination = {false}
                    getTdProps = {() => {
                        return {
                            style: {
                                height: 37
                            }
                        }
                    }}
                    getThProps = {() => {
                        return {
                            style: {
                                background: '#0000'
                            }
                        }
                    }}
                    NoDataComponent = {() => {
                        return <div className="rt-noData"><Loader size = 'tiny' active size='big'/></div>
                    }}
                    getTheadProps = {() =>{
                        return {
                            style: {
                                fontSize: 12
                            }
                        }
                    }}
                    
                    PaginationComponent = {() => {
                        return <div style = {{backgroundColor: '#f0f0f0', textAlign: 'right', height: 25}}><img style = {{marginRight: 20}} height={23} width={35} src={require('./assets/images/black_logo.fw.png')}/></div>
                    }}
                /> 
            </div>    
        )
    }
}
// '#dcdfe4'
function mapStateToProps(state){
    return{
        ...state
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {fetchGetGeneralData, fetchTeamsGoals, fetchLogin}, dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TableDataAggregate)

// maxWidth: table_columns[Table][table_option]['fields'][i].progress ? 330 : 
// (table_columns[Table][table_option]['fields'][0].progress ? ((window.innerWidth - 600)/(table_columns[Table][table_option]['fields'].length - 1)) : ((window.innerWidth - 280)/table_columns[Table][table_option]['fields'].length)),


// ((fil && fil[0] && fil[0].player_id && general_data_reducer.data.player_data[`${row.value},${fil[0].team_id}`]) ? 
// general_data_reducer.data.player_data[`${row.value},${fil[0].team_id}`].name.toUpperCase() : 
// (general_data_reducer.data.team_data[row.value] && general_data_reducer.data.team_data[row.value].team_name) ? 
// general_data_reducer.data.team_data[row.value].team_name.toUpperCase() : '')
                         